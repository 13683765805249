import React from "react";

import { LazyMotion, domMax, m } from "framer-motion";
import { useState, useEffect } from "react";

// ----------------------------------------------------------------------

export const SplashScreen = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <LazyMotion strict features={domMax}>
      <div
        style={{
          right: 0,
          width: "100%",
          bottom: 0,
          height: "100%",
          zIndex: 9998,
          display: "flex",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <>
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
              rotate: [0, 270, 0],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <img src="/logo.svg" width={64} height={64} alt="logo" />
          </m.div>

          <m.div
            animate={{
              scale: [1.6, 1, 1, 1.6, 1.6],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ["25%", "25%", "50%", "50%", "25%"],
            }}
            transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
            style={{
              width: 100,
              height: 100,
              position: "absolute",
              border: `solid 8px rgba(0, 120, 103, 0.24)`,
            }}
          />

          <m.div
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ["25%", "25%", "50%", "50%", "25%"],
            }}
            transition={{
              ease: "linear",
              duration: 3.2,
              repeat: Infinity,
            }}
            style={{
              width: 120,
              height: 120,
              position: "absolute",
              border: `solid 8px rgba(0, 120, 103, 0.24)`,
            }}
          />
        </>
      </div>
    </LazyMotion>
  );
};
